// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-architecture-js": () => import("./../../../src/pages/architecture.js" /* webpackChunkName: "component---src-pages-architecture-js" */),
  "component---src-pages-concierge-js": () => import("./../../../src/pages/concierge.js" /* webpackChunkName: "component---src-pages-concierge-js" */),
  "component---src-pages-custom-home-js": () => import("./../../../src/pages/custom-home.js" /* webpackChunkName: "component---src-pages-custom-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-properties-index-js": () => import("./../../../src/pages/properties/index.js" /* webpackChunkName: "component---src-pages-properties-index-js" */),
  "component---src-pages-remodelling-js": () => import("./../../../src/pages/remodelling.js" /* webpackChunkName: "component---src-pages-remodelling-js" */),
  "component---src-pages-submit-property-js": () => import("./../../../src/pages/submit-property.js" /* webpackChunkName: "component---src-pages-submit-property-js" */),
  "component---src-templates-property-details-js": () => import("./../../../src/templates/property-details.js" /* webpackChunkName: "component---src-templates-property-details-js" */)
}

